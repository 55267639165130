import {NgModule} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {RouterLink} from "@angular/router";
import {UiAuthModule, UiAuthService} from "@smatchy/ui-auth";
import {NotfoundComponent} from './presentations/components/notfound/notfound.component';
import {AppLayoutModule} from "./presentations/layout/app.layout.module";
import {BASE_PATH, Configuration} from "@smatchy/sdk";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {environment} from "../environments/environment";
import {TokenInterceptor} from "./token.interceptor";


function apiInitializer() {
  return environment.api;
}

@NgModule({
  declarations: [AppComponent, NotfoundComponent],
  imports: [
    AppRoutingModule,
    AppLayoutModule,
    RouterLink,
    UiAuthModule.forRoot({
      apiSignInUrl: environment.api + '/auth/sign-up',
      loginRoute: '/login',
      keyStorage: "smatchy:bo",
      apiProfileUrl: environment.api + '/api/v1/profile'
    })
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: Configuration,
      useFactory: (authService: UiAuthService) =>
        new Configuration({
          basePath: apiInitializer(),
          accessToken: authService.getAccessToken.bind(authService),
        }),
      deps: [UiAuthService],
      multi: false,
    },
    {
      provide: BASE_PATH,
      useFactory: apiInitializer,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
