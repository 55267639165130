import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  OnChanges,
  Signal,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TableModule} from "primeng/table";
import {DatePipe, JsonPipe, NgClass, NgOptimizedImage, NgStyle} from "@angular/common";
import {TagModule} from "primeng/tag";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ChipsModule} from "primeng/chips";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SidebarModule} from "primeng/sidebar";
import {AvatarModule} from "primeng/avatar";
import {GoogleMap, MapInfoWindow, MapMarker} from "@angular/google-maps";
import {EventFound} from "@smatchy/sdk";
import {EventDetailComponent} from "../event-detail/event-detail.component";

@Component({
  selector: 'bo-event-list-filter',
  templateUrl: './event-list-filter.component.html',
  imports: [
    TableModule,
    NgClass,
    TagModule,
    JsonPipe,
    NgOptimizedImage,
    DatePipe,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    ReactiveFormsModule,
    SidebarModule,
    AvatarModule,
    GoogleMap,
    MapMarker,
    MapInfoWindow,
    NgStyle,
    EventDetailComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListFilterComponent implements OnChanges {
  @Input() events!: EventFound[];
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  options: google.maps.MapOptions = {
    center: {lat: 46, lng: 3.5},
    zoom: 5
  };

  searchGroup = new FormGroup({
    search: new FormControl('')
  });

  searchFilter = signal('');


  openedSidebar = false;
  filteredEvents: Signal<EventFound[]>;
  // @ts-ignore
  selectedEvent = signal<EventFound>(undefined as EventFound);

  // isMobile = window.innerWidth <=768;


  constructor() {


    this.searchGroup.valueChanges.subscribe(res => {
      this.filter(res.search);
    });


    this.filteredEvents = computed(() => {
      if (!this.searchFilter()) {
        return this.events;
      } else {
        return this.events.filter(event => {
          const keys = Object.keys(event);
          for (const key of keys) {
            // @ts-ignore
            if (event[key]?.toString().toLowerCase().includes(this.searchFilter().toLowerCase())) {
              return true;
            }
          }
          return false
        });
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['events'] && changes['events'].currentValue.length) {
      // Reset search filter to computed filteredEvents for releoad
      this.searchFilter.set('loading');
      this.searchFilter.set('');
    }
  }


  openInfoWindow(marker: any, event: EventFound) {
    this.selectedEvent.set(event);
    this.infoWindow.open(marker);
  }

  selectEvent(event: EventFound) {
    this.selectedEvent.set(event);
    this.openedSidebar = true;
  }

  private filter(value: string | null | undefined) {
    this.searchFilter.set(value || "");
  }

}

