import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {AuthenticatedGuard} from './authenticated.guard';
import {UiAuthService} from "./ui-auth.service";
import {AdminGuard} from "./admin.guard";

export type UiAuthConfigOption = {
  apiSignInUrl?: string;
  apiProfileUrl?:string;
  loginRoute?: string;
  notAuthorizedRoute?: string;
  keyStorage?: string;
};

export interface UiAuthConfig {
  apiSignInUrl: string;
  apiProfileUrl:string;
  loginRoute: string;
  notAuthorizedRoute: (roles: string[]) => string;
  keyStorage?: string;
}

export const UI_AUTH_CONFIG_TOKEN = new InjectionToken<UiAuthConfig>('UIAuthConfig');

export const setupConfig = (config: UiAuthConfigOption): UiAuthConfig => {
  return {
    apiSignInUrl: config.apiSignInUrl || 'http://localhost:3000/auth/sign-up',
    apiProfileUrl:  config.apiProfileUrl || 'http://localhost:3000/api/v1/profile',
    loginRoute: config.loginRoute || 'login',
    keyStorage: config.keyStorage || undefined,
    notAuthorizedRoute: () => {
      return config.notAuthorizedRoute || 'login';
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    //
  ],
  providers: [AuthenticatedGuard,AdminGuard],

})
export class UiAuthModule {

  static forRoot(config: UiAuthConfigOption = {}): ModuleWithProviders<UiAuthModule>[] {
    return [
      {
        ngModule: UiAuthModule,
        providers: [
          UiAuthService,
          {
            provide: UI_AUTH_CONFIG_TOKEN,
            useValue: setupConfig(config),
          },
        ],
      },
    ];
  }
}
