import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {UiAuthService} from './ui-auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router, private readonly _authService: UiAuthService) {
  }

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const returnUrl = state && state.url;
    const isLoggedIn$: Observable<boolean> = this._authService.isLoggedIn();

    isLoggedIn$.subscribe((loggedIn) => {
      if (!loggedIn) {
        void this._router.navigate([this._authService.getLoginRoute()], {queryParams: {returnUrl}});
      }
    });


    return isLoggedIn$;
  }

  canActivateChild() {
    return this.canActivate();
  }
}
