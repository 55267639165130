<div class="flex flex-col gap-2">

  <!--  <div class="flex flex-row">-->
  <!--    @if (event.) {-->
  <!--      <img [alt]="event.title"-->
  <!--           [ngSrc]="event.backgroundUrl"-->
  <!--           class="rounded-full"-->
  <!--           width="32" height="32" style="vertical-align: middle"/>-->
  <!--    }-->
  <!--  </div>-->
  <p-toast/>

  <div class="font-bold text-2xl">
    {{ event.title }}
  </div>

  <div>
    <button pButton pRipple class="bg-primary px-4 py-2 gap-2" (click)="copyToClipBoard()">
      <i class="pi pi-copy"></i>
      Copier le lien de partage
    </button>
  </div>
  <div>
    <p-chip label="{{event.sportTitle}}"></p-chip>
  </div>

  <div class="flex flex-row items-center gap-x-4 mb-2 rounded-xl bg-slate-700 p-3">
    <i class="pi pi-calendar"></i>
    Début : {{ event.startDate  | date:'short' }}
  </div>


  <div class="font-bold text-xl text-decoration-underline">
    Participants
  </div>

  <div class="flex flex-col gap-4">
    @for (attendee of event.attendees; track attendee) {
      <li class="flex flex-row gap-4 items-center">
        {{ attendee.email }}
        <button pButton pRipple [routerLink]="['/users/', attendee.id]" class="bg-teal-500 px-4 py-2">
          Voir son profil
        </button>
      </li>
    }
  </div>

  <div>

    <div class="font-bold text-xl">Position</div>
    <google-map [options]="options" height="400px"
                width="100%">
      <map-marker [position]="markerPositions" [options]="markerOptions"/>

    </google-map>
  </div>


</div>





