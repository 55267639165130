import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  OnChanges,
  Signal,
  signal,
  SimpleChanges,
  WritableSignal
} from '@angular/core';
import {TableModule} from "primeng/table";
import {DatePipe, JsonPipe, NgClass, NgOptimizedImage, NgStyle} from "@angular/common";
import {TagModule} from "primeng/tag";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ChipsModule} from "primeng/chips";
import {ReactiveFormsModule} from "@angular/forms";
import {SidebarModule} from "primeng/sidebar";
import {UserResponse} from "@smatchy/sdk";
import {ChipModule} from "primeng/chip";
import {AvatarModule} from "primeng/avatar";
import {GoogleMap, MapMarker} from "@angular/google-maps";
import {UserStoreService} from '../../../services/user/user.store.service';
import {SimpleCardCountComponent} from "../simple-card-count/simple-card-count.component";

@Component({
  selector: 'bo-user-detail',
  templateUrl: './user-detail.component.html',
  imports: [
    TableModule,
    NgClass,
    TagModule,
    JsonPipe,
    NgOptimizedImage,
    DatePipe,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    ReactiveFormsModule,
    SidebarModule,
    ChipModule,
    AvatarModule,
    GoogleMap,
    MapMarker,
    SimpleCardCountComponent,
    NgStyle
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnChanges {
  @Input({required: true}) user!: UserResponse;
  avatarLabel = signal<string>('');


  options: WritableSignal<google.maps.MapOptions> = signal({
    center: {lat: 40, lng: -20},
    zoom: 7
  });
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  email = signal<string>('');
  countEventICreated: Signal<number>;

  #userStore = inject(UserStoreService);

  userEvents = this.#userStore.userEvents;
  userGroups = this.#userStore.userGroups;


  constructor() {
    this.countEventICreated = computed(() => {
      return this.userEvents().filter(e => e.ownerId === this.user.userId).length
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user.firstName && this.user.lastName) {
      this.avatarLabel.set(this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1));
    }

    if (this.user.location) {
      this.markerPositions = {
        lat: this.user.location.lat,
        lng: this.user.location.lon
      };
      this.options.set({
        zoom: 7,
        center: {
          lat: this.user.location.lat,
          lng: this.user.location.lon
        }
      })
    }
    this.email.set(this.user.user?.email || "");

    this.#userStore.getUserEvents(this.user.userId);
    this.#userStore.getUserGroups(this.user.userId);
  }


}
