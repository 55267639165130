import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from "./presentations/layout/app.layout.component";
import {NotfoundComponent} from './presentations/components/notfound/notfound.component';
import {AdminGuard, AuthenticatedGuard} from "@smatchy/ui-auth";
import {LoginComponent} from "./presentations/pages/auth/login/login.component";
import {EventsComponent} from "./presentations/pages/events/events.component";
import {UsersComponent} from "./presentations/pages/users/users.component";
import {PastEventsComponent} from "./presentations/pages/past-events/past-events.component";
import {UserComponent} from "./presentations/pages/user/user.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
        {
          path: '', component: AppLayoutComponent,
          children: [
            {
              path: '',
              loadChildren: () => import('./presentations/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
              path: 'events',
              component: EventsComponent
            },
            {
              path: 'past-events',
              component: PastEventsComponent
            },
            {
              path: 'users',
              component: UsersComponent
            },
            {
              path: 'users/:id',
              component: UserComponent
            },

          ],
          canActivate: [AuthenticatedGuard, AdminGuard],
          canActivateChild: [AuthenticatedGuard, AdminGuard],
        },
        {path: 'login', component: LoginComponent},
        {path: 'notfound', component: NotfoundComponent},
        {path: '**', redirectTo: '/notfound'}
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
      })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
