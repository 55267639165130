<div class="card">

  <p-sidebar [(visible)]="openedSidebar" [fullScreen]="true">
    @if (selectedEvent()) {
      <bo-event-detail [event]="selectedEvent()"/>
    }
  </p-sidebar>

  <div class="m-5">

    <form class="flex" [formGroup]="searchGroup">
      <p-inputGroup>
        <p-inputGroupAddon>
          <i class="pi pi-search"></i>
        </p-inputGroupAddon>
        <input pInputText placeholder="Rechercher" formControlName="search"/>
      </p-inputGroup>
    </form>
  </div>

  <google-map [options]="options" height="500px"
              width="100%">

    @for (event of filteredEvents(); track event.id) {
      <map-marker #marker="mapMarker"
                  [position]="{ lat: event.location.lat, lng: event.location.lon }"
                  [options]="{
                  draggable: false,clickable: true
                  }"
                  (mapClick)="openInfoWindow(marker, event)"/>

    }
    <map-info-window style="color: #0F262E">
      @if (selectedEvent()) {
        <div class="flex flex-col" style="color: #0F262E">
          <div class="font-bold text-2xl">{{ selectedEvent().title }}</div>


          <div class="flex flex-row items-center gap-x-4 ont-bold text-xl">
            <i class="pi pi-angle-right font-bold"></i>
            {{ selectedEvent().sportTitle }}
          </div>
          <div class="font-semibold">{{ selectedEvent().nbAttendees }} / {{ selectedEvent().maxNbAttendees }}inscrits
          </div>
          <div class="flex flex-row">
            {{ selectedEvent().startDate }} -
            {{ selectedEvent().endDate }}
          </div>

          <div class="flex flex-row">
            @if (selectedEvent().full) {
              <p-tag severity="danger" value="Complet"></p-tag>
            } @else {
              <p-tag severity="success"
                     value=" {{ selectedEvent().maxNbAttendees - selectedEvent().nbAttendees }} Places dispos"></p-tag>
            }
          </div>

          <div class="flex flex-row items-center gap-x-4">
            <i class="pi pi-map-marker font-bold"></i>
            {{ selectedEvent().locationAddress }}
          </div>
        </div>
      }

    </map-info-window>
  </google-map>


  <p-table
    #dt2
    [value]="filteredEvents()"
    dataKey="id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-striped"
    [tableStyle]="{ 'min-width': '75rem' }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="title" style="width:22%">Nom
          <p-sortIcon field="title"></p-sortIcon>
        </th>
        <th pSortableColumn="sportTitle" style="width:22%">Sport
          <p-sortIcon field="sportTitle"></p-sortIcon>
        </th>
        <th pSortableColumn="startDate" style="width:22%">Début
          <p-sortIcon field="startDate"></p-sortIcon>
        </th>
        <th pSortableColumn="endDate" style="width:22%">Fin
          <p-sortIcon field="endDate"></p-sortIcon>
        </th>

        <th pSortableColumn="locationAddress" style="width:12%">Lieux
          <p-sortIcon field="locationAddress"></p-sortIcon>
        </th>
        <th pSortableColumn="nbAttendees" style="width:12%">Nombres des participants
          <p-sortIcon field="nbAttendees"></p-sortIcon>
        </th>
        <th pSortableColumn="maxNbAttendees" style="width:12%">Nombres des participants max
          <p-sortIcon field="maxNbAttendees"></p-sortIcon>
        </th>
        <th pSortableColumn="level" style="width:12%">Niveau
          <p-sortIcon field="level"></p-sortIcon>
        </th>
        <th style="width:12%">Complet ?</th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr class="cursor-pointer" (click)="selectEvent(event)">
        <td class="flex flex-row gap-x-2 items-center">
          {{ event.title }}
        </td>
        <td>
          {{ event.sportTitle }}
        </td>
        <td>
          {{ event.startDate | date:'medium' }}
        </td>
        <td>
          {{ event.endDate | date:'medium' }}
        </td>
        <td>
          {{ event.locationAddress }}
        </td>
        <td>
          {{ event.nbAttendees }}
        </td>
        <td>
          {{ event.maxNbAttendees }}
        </td>
        <td>
          @if (event.acceptAnyLvl) {
            Tous niveaux
          } @else {
            {{ event.level }}
          }

        </td>
        <td>
          <i class="pi"
             [ngClass]="{ 'text-green-500 pi-check-circle': event.full, 'text-red-500 pi-times-circle': !event.full }"></i>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Aucun évènements futur</td>
      </tr>
    </ng-template>
  </p-table>
</div>





