import {IEnvironment} from "./i-environment";

export const environment: IEnvironment = {
  version: '1.0.0',
  production: false,
  // api: 'http://localhost:3000',
  api: 'https://smatchy-api.amagumolabs.net',
  firebaseConfig: {
    // apiKey: "AIzaSyAk65xztUp6dL812cZGxWbNbY2hmsfTQXc",
    // authDomain: "smatchy-dev.firebaseapp.com",
    // projectId: "smatchy-dev",
    // storageBucket: "smatchy-dev.appspot.com",
    // messagingSenderId: "1026169750230",
    // appId: "1:1026169750230:web:c7ec53d721f49071b27f77",
    // measurementId: "G-70V8E7ZCG8"
    apiKey: "AIzaSyAaiq5ET79I_4Js6KzTo5DyVikZWDOf3qw",
    authDomain: "smatchy-dev-ccf10.firebaseapp.com",
    projectId: "smatchy-dev-ccf10",
    storageBucket: "smatchy-dev-ccf10.firebasestorage.app",
    messagingSenderId: "126567483727",
    appId: "1:126567483727:web:5f943a63e6a2d9cfde5d3a",
    measurementId: "G-Q15NG3Y0EE"
  },
};
