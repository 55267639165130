import {Component, inject, OnInit} from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {AppConfig, LayoutService} from './presentations/layout/service/app.layout.service';
import {loadFirebase} from "./firebase-desktop";

@Component({
  // standalone: true,
  // imports: [
  //   RouterModule,
  //   AppLayoutModule
  // ],
  selector: 'bo-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

  private primeNgConfig = inject(PrimeNGConfig);
  private layoutService = inject(LayoutService);


  ngOnInit(): void {
    loadFirebase();
    this.primeNgConfig.ripple = true;


    //optional configuration with the default configuration
    const config: AppConfig = {
      ripple: true,                      //toggles ripple on and off
      inputStyle: 'outlined',             //default style for input elements
      menuMode: 'static',                 //layout mode of the menu, valid values are "static" and "overlay"
      colorScheme: 'dark',               //color scheme of the template, valid values are "light" and "dark"
      theme: 'lara-dark-teal',         //default component theme for PrimeNG
      scale: 14                           //size of the body font size to scale the whole application
    };
    this.layoutService.config.set(config);
  }

  title = 'backoffice';
}
