import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {environment} from "../environments/environment";


export const loadFirebase = () => {

  // const firebaseConfig = {
  //   apiKey: environment.firebaseConfig.apiKey,
  //   authDomain: 'smatchy-4e5ea.firebaseapp.com',
  //   projectId: 'smatchy-4e5ea',
  //   storageBucket: 'smatchy-4e5ea.appspot.com',
  //   messagingSenderId: '594087846436',
  //   appId: '1:594087846436:web:b9397218af50efa6836eb1',
  //   measurementId: 'G-QBEC08G116'
  // };

// Initialize Firebase
  const app = initializeApp(environment.firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);

};

