import {Component} from '@angular/core';

@Component({
  selector: 'bo-notfound',
  templateUrl: './notfound.component.html',
  // imports: [
  //   RouterLink
  // ],
  // standalone: true
})
export class NotfoundComponent {
}
