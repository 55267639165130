import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserStoreService} from "../../../services/user/user.store.service";
import {UserDetailComponent} from "../../components/user-detail/user-detail.component";

@Component({
  templateUrl: './user.component.html',
  imports: [
    UserDetailComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent {
  #route = inject(ActivatedRoute);
  #userService = inject(UserStoreService);

  userId = this.#route.snapshot.params['id'];
  user = this.#userService.user;

  constructor() {
    if (this.userId) {
      this.#userService.getUser(this.userId);
    }

  }

}

