import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {UiAuthService} from './ui-auth.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {

  constructor(private _router: Router, private readonly _uiAuthService: UiAuthService) {
  }

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const returnUrl = state && state.url;
    const isAdmin$ = this._uiAuthService.isAdmin();

    isAdmin$.subscribe(isAdmin => {
      if (!isAdmin) {
        alert('Vous n\'êtes pas autorisé');
        void this._router.navigate([this._uiAuthService.getLoginRoute()], {queryParams: {returnUrl}});
      }
    });
    return isAdmin$;
  }

  canActivateChild() {
    return this.canActivate();
  }
}
