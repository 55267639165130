<div class="layout-wrapper" [ngClass]="containerClass">
    <bo-topbar></bo-topbar>
    <div class="layout-sidebar">
        <bo-sidebar></bo-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <bo-footer></bo-footer>
    </div>
    <div class="layout-mask"></div>
</div>
