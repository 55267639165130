import {ChangeDetectionStrategy, Component, computed, inject, signal} from '@angular/core';
import {TableModule} from "primeng/table";
import {DatePipe, JsonPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {TagModule} from "primeng/tag";
import {UserStoreService} from "../../../services/user/user.store.service";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ChipsModule} from "primeng/chips";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SidebarModule} from "primeng/sidebar";
import {UserDetailComponent} from "../user-detail/user-detail.component";
import {UserResponse} from "@smatchy/sdk";
import {AvatarModule} from "primeng/avatar";

@Component({
  selector: 'bo-user-list',
  templateUrl: './user-list.component.html',
    imports: [
        TableModule,
        NgClass,
        TagModule,
        JsonPipe,
        NgOptimizedImage,
        DatePipe,
        InputGroupModule,
        InputGroupAddonModule,
        ChipsModule,
        ReactiveFormsModule,
        SidebarModule,
        UserDetailComponent,
        AvatarModule
    ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent {
  #userStore = inject(UserStoreService);

  searchGroup = new FormGroup({
    search: new FormControl('')
  });

  filteredUsers;
  searchFilter = signal('');

  #users = this.#userStore.users;
  openedSidebar = false;
  // @ts-ignore
  selectedUser = signal<UserResponse>(undefined as UserResponse);


  constructor() {
    this.searchGroup.valueChanges.subscribe(res => {
      this.filter(res.search);
    });

    this.filteredUsers = computed(() => {
      if (!this.searchFilter) {
        return this.#users();
      } else {
        return this.#users().filter(user => {
          return user.firstName?.toLowerCase().includes(this.searchFilter().toLowerCase()) ||
            user.lastName?.toLowerCase().includes(this.searchFilter().toLowerCase()) ||
            user.user?.email?.toLowerCase().includes(this.searchFilter().toLowerCase())
        });
      }
    })
  }

  filter(value: string | null | undefined) {
    this.searchFilter.set(value || "");
  }

  onUserSelected(user: UserResponse) {
    this.selectedUser.set(user);
    this.openedSidebar = true;
  }
}
