import {Injectable} from "@angular/core";

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword as desktopSignInWithEmailAndPassword,
  signInWithPopup as desktopSignInWithPopup,
  UserCredential,
  browserPopupRedirectResolver,
} from '@firebase/auth';
import {getAuth} from 'firebase/auth';
import {UiAuthService} from "@smatchy/ui-auth";
import {ProfileService} from "@smatchy/sdk";

export type AuthProvider = 'google' | 'facebook';

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(private readonly _uiAuthService: UiAuthService,
              private readonly _profileService: ProfileService) {
  }


  async signInProviderDesktop(provider: AuthProvider): Promise<string | undefined> {
    try {
      if (provider === 'google') {
        const user: UserCredential = await desktopSignInWithPopup(getAuth(), new GoogleAuthProvider(), browserPopupRedirectResolver);
        console.log('user', user);
        const firebaseToken = await user.user.getIdToken();
        console.log('firebaseToken', firebaseToken);
        this._uiAuthService.setAccessToken(firebaseToken);
        const apiToken = await this._uiAuthService.signInApi('google');
        console.log('apiToken', apiToken);
        return apiToken;
      } else if (provider === 'facebook') {
        const user: UserCredential = await desktopSignInWithPopup(getAuth(), new FacebookAuthProvider());
        const firebaseToken = await user.user.getIdToken();
        this._uiAuthService.setAccessToken(firebaseToken);
        const apiToken = await this._uiAuthService.signInApi('facebook');
        return apiToken;
      } else {
        return undefined;
      }
    } catch (e: any) {
      console.error(e);

      if (!e.code) {
        alert(e?.error?.message || e.message);
      }
      if (e.code.startsWith('auth/')) {
        alert('user_pwd_not_found');
      }
      return undefined;
    }
  }

  async signInEmailPwd(email: string, password: string): Promise<string | undefined> {
    try {
      const user: UserCredential = await desktopSignInWithEmailAndPassword(getAuth(), email, password);
      const firebaseToken = await user.user.getIdToken();
      this._uiAuthService.setAccessToken(firebaseToken);
      const apiToken = await this._uiAuthService.signInApi('email');
      return apiToken;
    } catch (e: any) {
      console.error(e);
      if (!e.code) {
        alert(e?.error?.message || e.message);
      }
      if (e.code.startsWith('auth/')) {
        alert('user_pwd_not_found');
      }
      return undefined;
    }
  }

}

