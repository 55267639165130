<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center">
    <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg"
         alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0">
    <div
      style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
      <form class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px" [formGroup]="signInForm">
        <div class="text-center mb-5">
          <div class="text-900 text-3xl font-medium mb-3">Welcome!</div>
          <span class="text-600 font-medium">Connecte toi to continue</span>
        </div>

        <div>
          <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
          <input id="email1" type="text" placeholder="Email address" pInputText class="w-full mb-5"
                 formControlName="email"
                 style="padding:1rem">

          <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
          <input id="password1" type="password" placeholder="Password" pInputText class="w-full mb-5"
                 formControlName="password"
                 style="padding:1rem">


          <button pButton pRipple label="Se connecter" class="w-full p-3 text-xl" (click)="loginEmailPwd()"
                  [disabled]="!signInForm.valid"></button>


          <div class="text-center">
            <p-button label="" [text]="true" [raised]="true" [rounded]="true" (click)="signInGoogle()">
              <img src="assets/google.svg"
                   alt="Google auth">
            </p-button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
