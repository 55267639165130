import {Component} from '@angular/core';
import {PasswordModule} from "primeng/password";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {LayoutService} from "../../../layout/service/app.layout.service";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ChipsModule} from "primeng/chips";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'bo-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [
    PasswordModule,
    FormsModule,
    RouterLink,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    ChipsModule
  ],
  styles: [`
    :host ::ng-deep .pi-eye,
    :host ::ng-deep .pi-eye-slash {
      transform: scale(1.6);
      margin-right: 1rem;
      color: var(--primary-color) !important;
    }
  `]
})
export class LoginComponent {

  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(public layoutService: LayoutService,
              private readonly _authService: AuthService,
              private readonly _router: Router) {
  }

  async loginEmailPwd() {
    if (this.signInForm.valid) {
      const userToken = await this._authService.signInEmailPwd(this.signInForm.value.email || "", this.signInForm.value.password || "");
      this.handleLoginToken(userToken);
    }

  }

  async signInGoogle() {
    const userToken = await this._authService.signInProviderDesktop('google');
    this.handleLoginToken(userToken);
  }

  private handleLoginToken(userToken: string | undefined) {
    if (userToken === '') {
      alert('login_error');
    } else {
      this._router.navigate(['/']);
    }
  }
}
