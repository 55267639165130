import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {UiAuthService} from "@smatchy/ui-auth";

@Injectable()
export class TokenInterceptor implements TokenInterceptor {

  constructor(private readonly authService: UiAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldHandle(request.url) === false) {
      return next.handle(request);
    }


    const token = this.authService.getAccessToken();
    request = request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(request);
  }

  shouldHandle(url: string) {
    const baseUrl = environment.api;
    if (!baseUrl) {
      return false;
    }

    // if request is not for api, do not intercept
    return url.startsWith(baseUrl) === true;
  }

}
