import {Inject, Injectable} from '@angular/core';
import {firstValueFrom, from, map, Observable, of} from 'rxjs';
import {UI_AUTH_CONFIG_TOKEN, UiAuthConfig} from "./ui-auth.module";
import {GetMyProfileResponse, UserProfileControllerGetMe200Response, UserProfilePopulatedEntity} from "@smatchy/sdk";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UiAuthService {
  private _token: string | undefined;
  private keyStorage = 'nest-baas:user';

  constructor(@Inject(UI_AUTH_CONFIG_TOKEN) private readonly _config: UiAuthConfig,
              private readonly httpClient: HttpClient,) {
    this.init();
  }

  isLoggedIn(): Observable<boolean> {
    if (!this._token) {
      this.init();
    }
    if (this._token && !this.getUser()) {
      return from(this.loadMyProfile()).pipe(map((res) => !!res));
    }
    return of(!!this._token);
  }

  getAccessToken(): string {
    return this._token || "";
  }

  private getUser() {
    const value = localStorage.getItem(this.keyStorage + "_user");
    if (value) {
      return JSON.parse(value)
    }
    return undefined;
  }

  private setUser(user: UserProfilePopulatedEntity) {
    return localStorage.setItem(this.keyStorage + "_user", JSON.stringify(user));
  }

  setAccessToken(token: string) {
    localStorage.setItem(this.keyStorage, token);
    this._token = token;
  }

  reset() {
    localStorage.removeItem(this.keyStorage);
  }

  getLoginRoute() {
    return this._config.loginRoute;
  }

  async signInApi(provider: string): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const res = await firstValueFrom(this.httpClient.post<{ access_token: string }>(this._config.apiSignInUrl, {
      provider
    }));
    this.setAccessToken(res.access_token);
    await this.loadMyProfile();
    return res.access_token;
  }

  async loadMyProfile(): Promise<GetMyProfileResponse | undefined> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const res = await firstValueFrom(this.httpClient.get<UserProfileControllerGetMe200Response>(this._config.apiProfileUrl));
    if (res.error) {
      console.error(res.error);
      return undefined;
    } else {
      if (res.value?.profile) {
        this.setUser(res.value?.profile);
      }
      return res.value;
    }

  }

  private init() {
    if (this._config.keyStorage) {
      this.keyStorage = this._config.keyStorage;
    }
    this._token = localStorage.getItem(this.keyStorage) || undefined;
  }

  isAdmin(): Observable<boolean> {
    if (this.getUser() === undefined) {
      return from(this.loadMyProfile()).pipe(map((res) => res?.profile.user.roles.includes("admin") || false));
    }
    return of(this.getUser()?.user.roles.includes("admin") || false);
  }
}
