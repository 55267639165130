import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TableModule} from "primeng/table";
import {DatePipe, JsonPipe, NgClass, NgOptimizedImage, NgStyle} from "@angular/common";
import {TagModule} from "primeng/tag";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {ChipsModule} from "primeng/chips";
import {ReactiveFormsModule} from "@angular/forms";
import {SidebarModule} from "primeng/sidebar";
import {EventFound} from "@smatchy/sdk";
import {ChipModule} from "primeng/chip";
import {AvatarModule} from "primeng/avatar";
import {GoogleMap, MapMarker} from "@angular/google-maps";
import {SimpleCardCountComponent} from "../simple-card-count/simple-card-count.component";
import {RouterLink} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import {MessageService} from 'primeng/api';

@Component({
  selector: 'bo-event-detail',
  templateUrl: './event-detail.component.html',
  imports: [
    TableModule,
    NgClass,
    TagModule,
    JsonPipe,
    NgOptimizedImage,
    DatePipe,
    InputGroupModule,
    InputGroupAddonModule,
    ChipsModule,
    ReactiveFormsModule,
    SidebarModule,
    ChipModule,
    AvatarModule,
    GoogleMap,
    MapMarker,
    SimpleCardCountComponent,
    NgStyle,
    RouterLink,
    ButtonModule,
    RippleModule,
    ToastModule
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./event-detail.component.scss'],
  providers: [MessageService]
})
export class EventDetailComponent implements OnChanges {
  @Input({required: true}) event!: EventFound;


  options: google.maps.MapOptions = {
    center: {lat: 40, lng: -20},
    zoom: 7
  };
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral = {lat: 0, lng: 0};


  constructor(private messageService: MessageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.event.location) {
      this.options.center = {
        lat: this.event.location.lat,
        lng: this.event.location.lon
      }
      this.markerPositions = {
        lat: this.event.location.lat,
        lng: this.event.location.lon
      }
    }
  }


  copyToClipBoard() {
    const link = 'https://smatchy.app/detail-activity/' + this.event.id;
    navigator.clipboard.writeText(link).then(() => {
      this.messageService.add({
        severity: 'success', summary: 'Success', detail: 'Lien copié dans le presse papier'
      });
    });
  }
}
