<div class="card">
  <p-sidebar [(visible)]="openedSidebar" position="right"  styleClass="w-1/2">
    @if (selectedUser()) {
      <bo-user-detail [user]="selectedUser()"></bo-user-detail>
    }
  </p-sidebar>

  <p-table
    #dt2
    [value]="filteredUsers()"
    dataKey="id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['firstName', 'lastName']"
    styleClass="p-datatable-striped"
    [tableStyle]="{ 'min-width': '75rem' }"
  >
    <ng-template pTemplate="caption">
      <form class="flex" [formGroup]="searchGroup">
        <p-inputGroup>
          <p-inputGroupAddon>
            <i class="pi pi-search"></i>
          </p-inputGroupAddon>
          <input pInputText placeholder="Rechercher" formControlName="search"/>
        </p-inputGroup>
      </form>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="firstName" style="width:22%">Nom
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th pSortableColumn="user.email" style="width:22%">Mail
          <p-sortIcon field="user.email"></p-sortIcon>
        </th>
        <th pSortableColumn="createdAt" style="width:22%">Inscrit le
          <p-sortIcon field="createdAt"></p-sortIcon>
        </th>
        <th pSortableColumn="sportLevels" style="width:12%">Nombres de sports
          <p-sortIcon field="sportLevels"></p-sortIcon>
        </th>
        <th style="width:12%">Push notification</th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr (click)="onUserSelected(user)" class="cursor-pointer">
        <td class="flex flex-row gap-x-2 items-center">
          @if (user.avatarUrl) {
            <img [alt]="user.user.displayName"
                 [ngSrc]="user.avatarUrl"
                 class="rounded-full"
                 width="32" height="32" style="vertical-align: middle"/>
          }@else{
            <p-avatar label="{{user.firstName?.substring(0,1) + user.lastName?.substring(0,1)}}" styleClass="mr-2" size="large" shape="circle"></p-avatar>
          }
          {{ user.firstName }} {{ user.lastName }}
        </td>
        <td>
          {{ user.user.email }}
        </td>
        <td>
          {{ user.createdAt | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ user.sportLevels.length }}
        </td>
        <td>
          <i class="pi"
             [ngClass]="{ 'text-green-500 pi-check-circle': user.pushToken, 'text-red-500 pi-times-circle': !user.pushToken }"></i>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Aucun utilisateur trouvés</td>
      </tr>
    </ng-template>
  </p-table>
</div>





