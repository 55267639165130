import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {EventStoreService} from "../../../services/event/event.store.service";
import {EventListFilterComponent} from "../../components/event-list-filter/event-list-filter.component";

@Component({
  templateUrl: './past-events.component.html',
  imports: [
    EventListFilterComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastEventsComponent {
  #eventsStore = inject(EventStoreService);

  pastEvents = this.#eventsStore.pastEvents;

}

