import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UserListComponent} from "../../components/user-list/user-list.component";

@Component({
  selector: 'bo-users',
  templateUrl: './users.component.html',
  imports: [
    UserListComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent {

}

