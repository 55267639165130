<div class="flex flex-col gap-2">

  <div class="flex flex-row">
    @if (user.avatarUrl) {
      <img [alt]="user.user.displayName"
           [ngSrc]="user.avatarUrl"
           class="rounded-full"
           width="32" height="32" style="vertical-align: middle"/>
    } @else {
      <p-avatar label="{{avatarLabel()}}" styleClass="mr-2" size="normal" shape="circle"></p-avatar>
    }
  </div>

  <div class="font-bold text-2xl">
    {{ user.firstName }} {{ user.lastName }}
  </div>

  <div>
    <p-chip label="{{user.sex}}"></p-chip>
  </div>

  <div class="flex flex-row items-center gap-x-4 mb-2 rounded-xl bg-slate-700 p-3">
    <i class="pi pi-envelope font-bold"></i>
    <a href="mailto:{{ email() }}">{{ email() }}</a>
  </div>

  <div class="flex flex-row items-center gap-x-4 mb-2 rounded-xl bg-slate-700 p-3">
    <i class="pi pi-map-marker font-bold"></i>
    {{ user.city }} - {{ user.country }}
  </div>

  <div class="flex flex-row items-center gap-x-4 mb-2 rounded-xl bg-slate-700 p-3">
    <i class="pi pi-plus-circle font-bold"></i>
    Inscrit depuis le : {{ user.createdAt  | date }}
  </div>


  <div class="font-bold text-xl text-decoration-underline">
    Sports
  </div>

  <section class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
    @for (sport of user.sportLevels; track sport) {
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-semibold mb-3">{{ sport.name }}</span>
            <div class="text-900 font-bold text-4xl">{{ sport.level }} / {{ sport.maxLevel }}</div>
          </div>
          <div class="flex align-items-center justify-content-center border-round"
               [ngStyle]="{height: '2.5rem'}">
            <span class="text-green-500 font-medium">{{ sport.date | date }} </span>
          </div>
        </div>
      </div>
    }
  </section>
  <div>

    <div class="font-bold text-xl">Position</div>
    @if(user.location){
      <google-map [options]="options()" height="400px"
                  width="100%">
        <map-marker [position]="markerPositions" [options]="markerOptions"/>

      </google-map>
    }@else{
      <div>
        Pas de position connue
      </div>
    }

  </div>


  @if (countEventICreated()) {
    <div>
      <bo-simple-card-count title="Activités crées" [count]="countEventICreated()" icon="pi-plus"/>
    </div>
  }

  <div>
    <div class="font-bold text-xl text-decoration-underline">
      Mes Groupes
    </div>

    <div class="flex flex-col  gap-4">
      @for (group of userGroups(); track group._id) {

        <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="md:flex">
            <div class="md:shrink-0">
              @if (group.backgroundUrl) {
                <img class="object-cover img-group" src="{{group.backgroundUrl}}" alt="{{group.name}}">
              }
            </div>
            <div class="p-4">
              <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{{ group.name }}</div>
              <div class="flex flex-row gap-x-4">
                <div>
                  @if (group.isAdmin) {
                    <p-tag severity="primary" value="ADMIN"></p-tag>
                  } @else if (group.imAmPending) {
                    <p-tag severity="warning" value="En attente d'acceptation"></p-tag>
                  } @else if (group.haveJoined) {
                    <p-tag severity="info" value="Membre"></p-tag>
                  }
                </div>

                <div>
                  <p-tag severity="success" value="{{group.sport.name}}"></p-tag>
                </div>

              </div>
              <div class="flex flex-row items-center gap-x-4 text-slate-500">
                <i class="pi pi-users font-bold"></i>
                {{ group.nbUsers }} membres
              </div>

              <p class="mt-2 text-slate-500 group-description">
                {{ group.description }}
              </p>

            </div>
          </div>
        </div>
      }

    </div>
  </div>


</div>





